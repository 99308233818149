import "./App.css";
import Home from "./components/Home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Gallery from "./components/gallery/Gallery";
import Featured from "./components/Featured/Featured";
import Events from "./components/Events/Events";
import Sponsors from "./components/Sponsors/Sponsors";
import Developers from "./components/Developers/Developers";
import Navbar from "./components/Navbar/Navbar";
import { useState } from "react";
import useMediaQuery from "./helpers/UseMediaQuery";
import NavMobile from "./components/Navbar/NavMobile";
import Card from "./Card/Card";
import Teams from "./components/Teams/Teams";
function App() {
  const [active, setActive] = useState("home");
  const [showSideMenu, setShowSideMenu] = useState(false);
  const query = "(max-width: 540px)";
  const isSmallScreen = useMediaQuery(query);
  return (
    <div className="App">
      <Router>
        {isSmallScreen ? (
          <NavMobile showNav={showSideMenu} setShowNav={setShowSideMenu} active={active} setActive={setActive} />
        ) : (
          <Navbar active={active} setActive={setActive} />
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/featured" element={<Featured />} />
          <Route path="/events" element={<Events />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/teams" element={<Teams />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
