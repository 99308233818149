import React, { useState, Component, useEffect } from "react";
import "./gallery.scss";
import Footer from "../Footer/Footer";
const Gallery = (props) => {
  const arr = [
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440376/public/images/uvfqr2s34kvqbodrqjta.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440372/public/images/edu2onozhtxwh2l8ulec.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440376/public/images/whkn0lubec41nbrlqshp.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440376/public/images/whkn0lubec41nbrlqshp.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440376/public/images/whkn0lubec41nbrlqshp.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440377/public/images/ydlv9fbrpxrowz44oxgw.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440372/public/images/cwfaf2lylypzqilnxlva.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440372/public/images/iwrxajblwqupwojirfnj.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440372/public/images/ffvsqimsseajngf1xasv.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440380/public/images/kd8jesodilkho5o8u0fy.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440379/public/images/knacivoyylzfkjfoqr3o.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440382/public/images/tkwfba7vk3yrkg8psbz7.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440372/public/images/drpifivy2omvlry0d4uy.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440382/public/images/ilogjaooj51xalh8q9kt.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440385/public/images/mkkoho6my4yonz5j9mpb.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440385/public/images/tydmrb3uj2wbctehrove.jpg",
    },
    {
      src: "https://res.cloudinary.com/dryfxhged/image/upload/v1665440377/public/images/ydlv9fbrpxrowz44oxgw.jpg",
    },
  ];

  return (
    <div className="gallery-main-container">
      <div className="body-gallery-img">
        {arr.map((ele, idx) => (
          <img className="img-gallery" src={ele?.src} alt="" />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
