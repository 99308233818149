import React from "react";
import { useNavigate } from "react-router";
import "../../styles/Footer.css"

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="top">
        <div className="about-confluence">
          <div className="heading">Confluence 2k23</div>
          <div className="description">
            Confluence 2023, NIT Kurukshetra's annual cultural extravaganza,
            defies description. A celebration of free spirits and artistic
            genius, it's the eagerly anticipated festival where creativity knows
            no bounds. Behind the scenes, Team Confluence—comprising clubs like
            Photog, ELAD, HLAD, Colours, Spic, MAD, FineArts, HTC, and AVA—works
            tirelessly to deliver an unforgettable experience. Join us in this
            cultural odyssey, a convergence of diverse talents that promises to
            transcend the ordinary. Let the celebration begin!
          </div>
        </div>
        <div className="links">
          <div className="link">
            <div onClick={() => navigate("/events")}>Events</div>
          </div>
          <div className="link">
            <div onClick={() => navigate("/featured")}>Featured</div>
          </div>
          <div className="link">
            <div onClick={() => navigate("/gallery")}>Gallery</div>
          </div>
          <div className="link">
            <div onClick={() => navigate("/sponsors")}>Sponsors</div>
          </div>
          <div className="link">
            <div onClick={() => navigate("/developers")}>Developers</div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="bottom">&#169;Confluence 2023. All rights reserved</div>
    </div>
  );
};

export default Footer;
