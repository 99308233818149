const settings = {

    // BACKEND_URL: 'http://localhost:4044',

    
    BACKEND_URL: 'https://confluence-backend.onrender.com'

}



export default settings
