import React from "react";
import "./featured.scss";
import FeaturedCard from "./Featured_card";

const Featured = () => {
  return (
    <div className="bg">
      <FeaturedCard />
    </div>
  );
};

export default Featured;
