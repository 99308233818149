import React from "react";
import "./Card.css";
const Card = ({ event, image }) => {
  const cardStyle = {
    backgroundImage: `url(${event.image})`,
    backgroundSize: "100% 100%", // Stretch the image to cover the entire card
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "100%",
    maxWidth: "250px",
    height: "300px",
  };
  const openNewTab = (eventlink) => {
    const decodedLink = decodeURIComponent(eventlink);
    const sanitizedLink = decodedLink.replace("<p>", "").replace("</p>", ""); // Remove <p> tags if present
    if (sanitizedLink.startsWith("http") || sanitizedLink.startsWith("https")) {
      window.open(sanitizedLink, "_blank");
    } else {
      console.error("Invalid URL:", sanitizedLink);
    }
  };
  return (
    <section class="wrapper">
      <div class="card-event" data-effect="blur" style={cardStyle}>
        <button class="card-event__save  js-save" type="button">
          <i class="fa  fa-bookmark"></i>
        </button>
        <figure class="card-event__image">
          <img src={event?.image} alt="Short description" />
        </figure>
        <div class="card-event__header">
          <figure class="card-event__profile">
            <img src={image} alt="Short description" />
          </figure>
        </div>
        <div class="card-event__body">
          <h3 class="card-event__name">{event.eventName}</h3>
          <p class="card-event__job">{event.venue}</p>
          {/* <p class="card-event__bio">{event.venue}</p> */}
        </div>
        <div class="card-event__footer">
          <button
            className="content-bottom-button"
            onClick={() => openNewTab(event?.description)}
          >
            Register
          </button>
          <p class=""></p>
        </div>
      </div>
    </section>
  );
};

export default Card;
