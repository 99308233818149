import React, { useEffect, useState } from "react";
// import { Modal, ModalBody } from "reactstrap";

import "./Sponsors.css";
import eventsActions from "../../actions/events.Actions";
import Footer from "../Footer/Footer";

const Sponsors = () => {
  const [list, setList] = useState([]);
  useEffect(() => {
    fetch();
  }, []);
  const fetch = () => {
    eventsActions.getSponsor((err, res) => {
      if (err) {
      } else {
        setList(res.data);
        console.log(res.data);
      }
    });
  };
  let background =
    "https://raw.githubusercontent.com/ankitk84/confluence-2022/main/sponsor-bursh-1.png";

  const sponsors = [
    {
      name: "Drishti",
      role: "Title",
      image:
        "https://www.kikk.be/2017/files/library/Sponsors/02_Founding-partners/04_Media-partners/03_Partners/05_Friends/03_Partners/FWB.jpg",
      redirectURL: "google.com",
    },

    {
      name: "Drishti",
      role: "Food",
      image:
        "https://www.kikk.be/2017/files/library/Sponsors/02_Founding-partners/04_Media-partners/03_Partners/05_Friends/03_Partners/FWB.jpg",
      redirectURL: "google.com",
    },
  ];

  let titleSponsor = [];
  let coTitleSponsor = [];
  let otherSponsor = [];
   let fashion =[];
  list.map((e) => {
    if (e.role === "Title") {
      titleSponsor.push(e);
      // otherSponsor.push(e);
    } else if (e.role === "Co Title") {
      coTitleSponsor.push(e);
    } else if (e.role === "Fashion Partner") {
      fashion.push(e);
    } else {
      otherSponsor.push(e);
      // titleSponsor.push(e);
    }
  });

  return (
    <>
      <div class="bg-drak sponsor-main">
        <div class="container py-5 downsection">
          <div class="row mt-4">
            <div class="col-lg-5">
              <h2
                class="display-4 font-weight-light font-head"
                style={{
                  color: "white",
                  fontFamily: "ClashDisplay",
                  fontWeight: "bold",
                }}
              >
                Sponsors
              </h2>
            </div>
          </div>

          <div class="row text-center  ">
            <div className="sponsor-title">
              <h1> Title Sponsors</h1>
            </div>
          </div>
          <div class="row text-center justify-content-md-center ">
            {titleSponsor?.map((e, key) => {
              return (
                <div
                  key={key}
                  class="col-xl-3 col-sm-6 mb-5 justify-content-center"
                >
                  <div class="sponsor-division">
                    <div
                      class="spons-image"
                      style={{ backgroundImage: `url(${background})` }}
                    >
                      <a
                        href={e.reDirectUrl}
                        type="button"
                        layout="sponsor"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span class="u-visually-hidden">{e.name}</span>
                        <img src={e.image} alt={e.name}></img>
                      </a>
                    </div>
                    <div className="sponsor-name" style={{ color: "#fff" }}>
                      <h3>{e.name}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div class="row text-center justify-content-md-center">
            {coTitleSponsor.length !== 0 ? (
              <>
                <div className="sponsor-title">
                  <h1>Associate Partners</h1>
                </div>
              </>
            ) : (
              <></>
            )}

            {coTitleSponsor?.map((e) => {
              return (
                <div class="col-xl-3 col-sm-6 mb-5 justify-content-center">
                  <div class="sponsor-division">
                    <div
                      class="spons-image"
                      style={{ backgroundImage: `url(${background})` }}
                    >
                      <a
                        href={e.reDirectUrl}
                        type="button"
                        layout="sponsor"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span class="u-visually-hidden">{e.name}</span>
                        <img src={e.image} alt={e.name}></img>
                      </a>
                    </div>
                    <div className="sponsor-name" style={{ color: "#fff" }}>
                      <h3>{e.name}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div class="row text-center justify-content-md-center">
            {fashion.length !== 0 ? (
              <>
                <div className="sponsor-title">
                  <h1>Fashion Partner </h1>
                </div>
              </>
            ) : (
              <></>
            )}

            {fashion?.map((e) => {
              return (
                <div class="col-xl-3 col-sm-6 mb-5 justify-content-center">
                  <div class="sponsor-division">
                    <div
                      class="spons-image"
                      style={{ backgroundImage: `url(${background})` }}
                    >
                      <a
                        href={e.reDirectUrl}
                        type="button"
                        layout="sponsor"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span class="u-visually-hidden">{e.name}</span>
                        <img src={e.image} alt={e.name}></img>
                      </a>
                    </div>
                    <div className="sponsor-name" style={{ color: "#fff" }}>
                      <h3>{e.name}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div class="row text-center justify-content-md-center">
            {otherSponsor.length !== 0 ? (
              <>
                <div className="sponsor-title">
                  <h1>Other Sponsors</h1>
                </div>
              </>
            ) : (
              <></>
            )}

            {otherSponsor?.map((e) => {
              return (
                <div class="col-xl-3 col-sm-6 mb-5  ">
                  <div class="sponsor-division">
                    <div
                      class="spons-image"
                      style={{ backgroundImage: `url(${background})` }}
                    >
                      <a
                        href={e.reDirectUrl}
                        type="button"
                        layout="sponsor"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span class="u-visually-hidden">{e.name}</span>
                        <img src={e.image} alt={e.name}></img>
                      </a>
                    </div>
                    <div className="sponsor-name" style={{ color: "white" }}>
                      <h3>{e.name}</h3>
                      {/* <h4>{e.role} Sponsor</h4> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Sponsors;
