import React from "react";
import "../../styles/Developers.css";

import Footer from "../Footer/Footer";
import gmail from "../../assets/gmail.png";
import instagram from "../../assets/instagram.png";
import { motion } from "framer-motion";
import aaryav from "../../teamsPhotos/Aaryav.png";
import akansha from "../../teamsPhotos/Akansha_Arora.png";
import amansharma from "../../teamsPhotos/Aman_Sharma.png";
import aman from "../../teamsPhotos/Aman.png";
import anurag from "../../teamsPhotos/Anurag.png";
import arnav from "../../teamsPhotos/Arnav.png";
import avtans from "../../teamsPhotos/Avtans.png";
import bharat from "../../teamsPhotos/BHARAT.png";
import dev from "../../teamsPhotos/dev.png";
import gopal from "../../teamsPhotos/gopal.png";
import gourav from "../../teamsPhotos/Gourav_Garhwal.png";
import harsh from "../../teamsPhotos/Harsh.png";
import jiya from "../../teamsPhotos/jiya.png";
import KUNAL_UPADHYAY from "../../teamsPhotos/KUNAL_UPADHYAY.png";
import keshav from "../../teamsPhotos/Keshav.png";
import kunal from "../../teamsPhotos/Kunal.png";
import manjeet from "../../teamsPhotos/Manjeet_Kumar.png";
import amandeep from "../../teamsPhotos/amandeep.png";
import muskan from "../../teamsPhotos/MUSKAN.png";
import natasha from "../../teamsPhotos/natasha.png";
import naved from "../../teamsPhotos/NAVED_ALI.png";
import neeraj from "../../teamsPhotos/Neêraj_Sharma.png";
import prajjwal from "../../teamsPhotos/PRAJJAWAL-preview.png";
import parth from "../../teamsPhotos/parth.png";
import rahul from "../../teamsPhotos/Rahul_Rathore.png";
import ritika from "../../teamsPhotos/Ritika_Mittal.png";
import saket from "../../teamsPhotos/Saket_Raman.png";
import soumya from "../../teamsPhotos/SOUMYA_KHANDAL.png";
import surbhi from "../../teamsPhotos/SURBHI.png";
import swasti from "../../teamsPhotos/Swasti.png";
import taniya from "../../teamsPhotos/Taniya.png";
import utsav from "../../assets/utsav.png";
import vedant from "../../teamsPhotos/VEDANT.png";
import vibha from "../../teamsPhotos/VIBHA.png";
import vrinda from "../../teamsPhotos/Vrinda.png";
import vidit from "../../teamsPhotos/Vidit_Nayal.png";
import yogesh from "../../teamsPhotos/Yogesh.png";

const Teams = () => {
  const teams = [
    {
      name: "Kunal Malik",
      img: kunal,
      role: "Secretary - The Photography Club",
      gmail: "kunalmalikgr8@gmail.com",
      instagram: "https://instagram.com/kunal_hr_12?igshid=MzRlODBiNWFlZA==",
    },
    {
      name: "Dev Bansal",
      img: dev,
      role: "Secretary - The Photography Club",
    },
    {
      name: "Gopal Tripathi",
      img: gopal,
      role: "Secretary - The Photography Club",
    },
    {
      name: "Natasha",
      img: natasha,
      role: "Secretary - The Photography Club",
    },
    {
      name: "Utsav Soni",
      img: utsav,
      role: "Sponsorship - The Photography Club",
      instagram: "https://www.instagram.com/guycalledutsav/",
    },
    {
      name: "Naved Ali",
      img: naved,
      role: "Secretary - Fine Arts & Modelling ",
    },
    {
      name: "Muskan",
      img: muskan,
      role: "Secretary - Fine Arts & Modelling ",
    },
    // {
    //   name: "Yashwant Raj",
    //   img: vidit,
    //   role: "Secretary - Fine Arts & Modelling ",
    // },
    {
      name: "Bharat",
      img: bharat,
      role: "Secretary - Fine Arts & Modelling ",
    },
    {
      name: "Harshvardhan Das",
      img: harsh,
      role: "Secretary - Managing And Directing Club ",
      instagram:"https://instagram.com/_harsh_vardhan____?igshid=OGQ5ZDc2ODk2ZA==",
    },
    {
      name: "Jiya Prakash",
      img: jiya,
      role: "Secretary - Managing And Directing Club ",
    },
    {
      name: "Arnav Roy",
      img: arnav,
      role: "Secretary - Managing And Directing Club ",
      instagram:"https://instagram.com/space.walkr?igshid=YTQwZjQ0NmI0OA==",
    },
    {
      name: "Akansha Arora ",
      img: akansha,
      role: "Secretary - Managing And Directing Club ",
    },
    {
      name: "Keshav Mittal",
      img: keshav,
      role: "Sponsorship - Managing And Directing Club ",
      instagram:"https://instagram.com/keshav.mittal_?igshid=OGQ5ZDc2ODk2ZA==",
    },
    {
      name: "Yogesh",
      img: yogesh,
      role: "Secretary - Literary and Debating Club",
      instagram:"https://instagram.com/yogesh_joshi.official?igshid=NzZlODBkYWE4Ng==",
    },
    {
      name: "Soumya Khandal",
      img: soumya,
      role: "Secretary - Literary and Debating Club",
    },
    {
      name: "Avtans Dixit ",
      img: avtans,
      role: "Secretary - Literary and Debating Club",
    },
    {
      name: "Swasti Verma",
      img: swasti,
      role: "Secretary - Literary and Debating Club",
    },
    {
      name: "Kunal Upadhyay ",
      img: KUNAL_UPADHYAY,
      role: "Sponsorship - Literary and Debating Club",
    },
    {
      name: "Vidit Nayal",
      img: vidit,
      role: "Sponsorship - Literary and Debating Club",
    },
    {
      name: "Vedant Nigam ",
      img: vedant,
      role: "Secretary - Spicmacy",
    },
    {
      name: "Anurag Shrestha ",
      img: anurag,
      role: "Secretary - Spicmacy",
    },
    {
      name: "Aman Saini",
      img: aman,
      role: "Sponsorship - Spicmacy",
    },
    {
      name: "Gourav Garhwal",
      img: gourav,
      role: "Secretary - Audio Visual Aid",
    },
    {
      name: "Taniya Yadav",
      img: taniya,
      role: "Secretary - Audio Visual Aid",
    },
    {  /*Photo missing*/
      name: "Amandeep",
      img: amandeep,
      role: "Secretary - Audio Visual Aid",
    },
    {
      name: "Neeraj Sharma",
      img: neeraj,
      role: "Sponsorship - Audio Visual Aid",
    },
    {
      name: "Parth Taneja",
      img: parth,
      role: "Secretary - Hiking and Trekking club ",
      instagram:"https://instagram.com/_parthtaneja?igshid=OGQ5ZDc2ODk2ZA==",
    },
    // {
    //   name: "",
    //   img: neeraj,
    //   role: "Secretary - Hiking and Trekking club ",
    // },
    {
      name: "Aaryav Sethi",
      img: aaryav,
      role: "Secretary - Colours ",
    },
    {
      name: "Ritika Mittal",
      img: ritika,
      role: "Secretary - Colours ",
      instagram:"https://instagram.com/ritika.vibes?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr",
    },
    {
      name: "Rahul Rathore",
      img: rahul,
      role: "Sponsorship - Colours ",
    },
    {
      name: "Manjeet Gujjar",
      img: manjeet,
      role: "Secretary - Students Club for Social Activity (SCSA)",
    },
    {
      name: "Vibha srivastava ",
      img: vibha,
      role: "Secretary - Students Club for Social Activity (SCSA)",
    },
    {
      name: "Saket Raman",
      img: saket,
      role: "Secretary - MCC",
    },
    {
      name: "Aman Sharma",
      img: amansharma,
      role: "Secretary - MCC",
    },
    
    {
      name: "Vrinda",
      img: vrinda,
      role: "Secretary - PG Club",
    },
    {
      name: "Surbhi Singh ",
      img: surbhi,
      role: "Secretary - PG Club",
    },
  ];

  return (
    <div class="developers-container">
      <div className="dev-main">
        <div className="header">Team Confluence</div>
        <div className="dev-card-container">
          {teams.map((e, idx) => (
            <motion.div
              variants={{
                hidden: { opacity: 0, scale: 0.5 },
                visible: { opacity: 1, scale: 1 },
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1 }}
              className="dev-card"
            >
              <div className="dev-img">
                <img src={e.img} alt="" />
              </div>
              <div className="dev-details">
                <div className="dev-name">{e.name}</div>
                <span className="dev-role">{e.role}</span>
                <div className="dev-social">
                  {/* <img
                    onClick={() => window.open(e.gmail, "_blank")}
                    src={gmail}
                    alt=""
                  /> */}
                  <img
                    onClick={() => window.open(e.instagram, "_blank")}
                    src={instagram}
                    alt=""
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Teams;
