/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import "../../styles/Home.css";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from "react-parallax";
import bgMain from "../../assets/bgMain.jpg";
import aurora from "../../assets/aurura.png";
import mountains from "../../assets/mountain.png";
import full from "../../assets/full.png";
import extend from "../../assets/extend.png";
import event from "../../assets/events.png";
import featured from "../../assets/featured.png";
import Footer from "../Footer/Footer";
import useMediaQuery from "../../helpers/UseMediaQuery";
import { motion, useInView, useAnimation } from "framer-motion";

const Home = () => {
  const leftCard1 = useRef(null);
  const isInViewLC1 = useInView(leftCard1, { once: true });
  const mainControlLC1 = useAnimation();

  const leftCard2 = useRef(null);
  const isInViewLC2 = useInView(leftCard2, { once: true });
  const mainControlLC2 = useAnimation();

  const leftCard3 = useRef(null);
  const isInViewLC3 = useInView(leftCard3, { once: true });
  const mainControlLC3 = useAnimation();

  const rightCard1 = useRef(null);
  const isInViewRC1 = useInView(rightCard1, { once: true });
  const mainControlRC1 = useAnimation();

  const rightCard2 = useRef(null);
  const isInViewRC2 = useInView(rightCard2, { once: true });
  const mainControlRC2 = useAnimation();

  const rightCard3 = useRef(null);
  const isInViewRC3 = useInView(rightCard3, { once: true });
  const mainControlRC3 = useAnimation();

  useEffect(() => {
    if (isInViewLC1) {
      mainControlLC1.start("visible");
    }
    if (isInViewLC2) {
      mainControlLC2.start("visible");
    }
    if (isInViewLC3) {
      mainControlLC3.start("visible");
    }
    if (isInViewRC1) {
      mainControlRC1.start("visible");
    }
    if (isInViewRC2) {
      mainControlRC2.start("visible");
    }
    if (isInViewRC3) {
      mainControlRC3.start("visible");
    }
  }, [
    isInViewLC1,
    isInViewLC2,
    isInViewLC3,
    isInViewRC1,
    isInViewRC2,
    isInViewRC3,
  ]);

  const background = {
    image: bgMain,
    translateY: [0, -20],
    opacity: [1, 0.5],
    scale: [1, 1.4, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const headline = {
    translateY: [0, 100],
    opacity: [1, 0],
    scale: [1, 0.7, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 250 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
        className="inset center aurora-container"
      >
        <img className="aurora" src={aurora} alt="" />
        <div className="dates">19 - 22 October</div>
      </motion.div>
    ),
  };

  const foreground = {
    image: mountains,
    translateY: [0, -8],
    scale: [1, 1.2, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  };

  const gradientOverlay = {
    opacity: [0, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: <div className="gradient inset" />,
  };

  const query1 = "(max-width: 540px)";
  const query2 = "(max-width: 960px)";
  const query3 = "(max-width: 1520px)";
  const isSmallScreen1 = useMediaQuery(query1);
  const isSmallScreen2 = useMediaQuery(query2);
  const isSmallScreen3 = useMediaQuery(query3);

  return (
    <ParallaxProvider>
      <div className="App-parallax">
        <ParallaxBanner
          layers={[background, headline, foreground, gradientOverlay]}
          className="full"
        />
        <div className="home-container">
          <Parallax
            blur={0}
            bgImage={full}
            bgImageAlt="Background"
            strength={1000}
            style={{
              height: isSmallScreen1
                ? "fit-content"
                : isSmallScreen2
                ? "fit-content"
                : isSmallScreen3
                ? "fit-content"
                : "fit-content",
            }}
          >
            <div className="content-layer">
              <div className="About">
                <div className="title">About</div>
                <div className="cards-container">
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, x: -250 },
                      visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={mainControlLC1}
                    transition={{ duration: 1 }}
                    ref={leftCard1}
                    className="card"
                  >
                    <div className="header">Confluence 2023</div>
                    <div className="body">
                      <div className="bar"></div>
                      <p className="content">
                        Confluence is the annual cultural festival of NIT
                        Kurukshetra, and it is one of the most awaited cultural
                        festivals in India. It is a cultural extravaganza beyond
                        words, beyond description. Confluence is the coming
                        together of free spirits, like-minded zealous youth and
                        a celebration of culture of true artistic genius. Its
                        urge to deliver the maximum is what makes it one of the
                        most awaited cultural festival.
                        {/* Confluence is made
                        possible by Team Confluence, a group of clubs: <br />
                        <ul>
                          <li>
                            Photography Club: Photography enthusiasts, hosting
                            workshops, competitions, and capturing Confluence
                            moments.
                          </li>
                          <li>
                            MAD: Managing and Directing Club, important role to
                            play in various fests and pre-shows of NIT
                            Kurukshetra and is the principle organizer of dance,
                            music, dramatic events of annual cultural fests and
                            pre-shows for year-long.
                          </li>
                          <li>
                            LAD: Literature and debating club, organizing
                            debates, poetry slams, and creative writing
                            competitions.
                          </li>
                          <li>
                            Colours: Focusing on mental health awareness,
                            offering workshops, talks, and panel discussions.
                          </li>
                          <li>
                            Spicmacy: Music lovers, arranging concerts,
                            workshops, and jam sessions.
                          </li>
                          <li>
                            Fine Arts: Art enthusiasts, hosting painting and
                            sculpture competitions, and photography exhibitions.
                          </li>
                          <li>
                            HTC: Hiking and trekking club, organizing treks to
                            nearby hills and forests.
                          </li>
                          <li>
                            AVA: Audio and visual club, managing all audio and
                            visual aspects of Confluence.
                          </li>
                        </ul> */}
                      </p>
                    </div>
                  </motion.div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, x: 250 },
                      visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={mainControlRC1}
                    transition={{ duration: 1 }}
                    ref={rightCard1}
                    className="card"
                  >
                    <div className="header">Aurora Alchemy</div>
                    <div className="body">
                      <div className="bar"></div>
                      <p className="content">
                        The esoteric practise of aurora alchemy combines the
                        transformational power of alchemy with the mystical
                        beauty of the aurora borealis. In an effort to harness
                        the power and majesty of the Northern Lights, it is a
                        special mix of traditional knowledge and contemporary
                        understanding. The fundamental tenet of Aurora Alchemy
                        is the conviction that these heavenly lights possess
                        significant spiritual significance and can act as a
                        channel for enlightenment and personal development.
                      </p>
                    </div>
                  </motion.div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, x: -250 },
                      visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={mainControlLC2}
                    transition={{ duration: 1 }}
                    ref={leftCard2}
                    className="card"
                  >
                    <div className="extend">
                      <img src={extend} alt="" />
                      <div className="header">Events</div>
                    </div>
                    <div className="body round-all">
                      <p className="content">
                        Experience the power of creativity and inspiration at
                        our events. We bring together artists, thinkers, and
                        innovators from across different fields to spark new
                        ideas and share their stories. Whether you're a creative
                        professional or just looking to be inspired, our events
                        are the perfect place to find your next big idea
                      </p>
                    </div>
                  </motion.div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, x: 250 },
                      visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    animate={mainControlRC2}
                    transition={{ duration: 1 }}
                    ref={rightCard2}
                    className="card"
                  >
                    <div className="extend">
                      <img src={extend} alt="" />
                      <div className="header">Featured</div>
                    </div>
                    <div className="body round-all">
                      <p className="content">
                        Embark on a musical journey at Confluence 2023! Groove
                        to electrifying DJ nights and be mesmerized by stellar
                        performances from renowned Bollywood singers. Previous
                        editions featured stars like Yo Yo Honey Singh, Jubin
                        Nautiyal, Mohd Irfan, and Ash King, setting the stage
                        for another unforgettable celebration. 🎤🌟
                      </p>
                    </div>
                  </motion.div>
                </div>
                <div className="ef"></div>
              </div>
              <Footer />
            </div>
          </Parallax>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default Home;
