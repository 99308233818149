import React from "react";
import close from "../../assets/close.png";
import "../../styles/NavMobile.css";
import { useNavigate } from "react-router";
import aurora from "../../assets/aurura.png";
import hamburger from "../../assets/hamburger.png";

const NavMobile = ({ showNav, setShowNav, setActive, active }) => {
  const navigate = useNavigate();
  return (
    <>
      {!showNav && (
        <div className="topSide">
          <button onClick={() => navigate("/")} className="logo">
            <img src={aurora} alt="" />
          </button>
          <button onClick={() => setShowNav(true)} className="hamburger">
            <img src={hamburger} alt="" />
          </button>
        </div>
      )}
      <div className={`navMobile ${showNav ? "show" : "hide"}`}>
        {showNav && (
          <>
            <div onClick={() => setShowNav(false)} className="top">
              <img src={close} alt="" />
            </div>
            <div className="menu-container">
              <div
                onClick={() => {
                  setActive("home");
                  navigate("/");
                  setShowNav(false);
                }}
                className={`menu-item ${active === "home" ? "active" : ""}`}
              >
                Home
              </div>
              <div
                onClick={() => {
                  setActive("gallery");
                  navigate("/gallery");
                  setShowNav(false);
                }}
                className={`menu-item ${active === "gallery" ? "active" : ""}`}
              >
                Gallery
              </div>
              <div
                onClick={() => {
                  setActive("events");
                  navigate("/events");
                  setShowNav(false);
                }}
                className={`menu-item ${active === "events" ? "active" : ""}`}
              >
                Events
              </div>
              <div
                onClick={() => {
                  setActive("featured");
                  navigate("/featured");
                  setShowNav(false);
                }}
                className={`menu-item ${active === "featured" ? "active" : ""}`}
              >
                Featured
              </div>
              <div
                onClick={() => {
                  setActive("sponsors");
                  navigate("/sponsors");
                  setShowNav(false);
                }}
                className={`menu-item ${active === "sponsors" ? "active" : ""}`}
              >
                Sponsors
              </div>
              <div
                onClick={() => {
                  setActive("developers");
                  navigate("/developers");
                  setShowNav(false);
                }}
                className={`menu-item ${
                  active === "developers" ? "active" : ""
                }`}
              >
                Developers
              </div>
              <div
                onClick={() => {
                  setActive("teamconfluence");
                  navigate("/teams");
                  setShowNav(false);
                }}
                className={`menu-item ${
                  active === "teamconfluence" ? "active" : ""
                }`}
              >
                Team Confluence
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NavMobile;
