import React from "react";
import "../../styles/Navbar.css";
import aurora from "../../assets/aurura.png";
import { useNavigate } from "react-router-dom";
import gallery from "../../assets/gallery.png";
import group from "../../assets/group.png";

import events from "../../assets/eventsIcon.png";
import featured from "../../assets/feature.png";
import sponsors from "../../assets/sponsor.png";
import developers from "../../assets/devIcon.png";

const Navbar = ({ active, setActive }) => {
  const navigate = useNavigate();
  return (
    <div className={`navbar-container`}>
      <button
        onClick={() => {
          setActive("home");
          navigate("/");
        }}
        className="aurora-btn"
      >
        <img className="aurora-icon" src={aurora} alt="" />
      </button>
      <div className="main-menu-container">
        <div className="line-top"></div>
        <div
          onClick={() => {
            setActive("gallery");
            navigate("/gallery");
          }}
          className={`dot-container ${active === "gallery" ? "active" : ""}`}
        >
          <img className="dot" src={gallery} alt="" />
          <span className="menu-item">Gallery</span>
        </div>
        <div className="line-divide"></div>
        <div
          onClick={() => {
            setActive("events");
            navigate("/events");
          }}
          className={`dot-container ${active === "events" ? "active" : ""}`}
        >
          <img className="dot" src={events} alt="" />
          <span className="menu-item">Events</span>
        </div>
        <div className="line-divide"></div>
        <div
          onClick={() => {
            setActive("featured");
            navigate("/featured");
          }}
          className={`dot-container ${active === "featured" ? "active" : ""}`}
        >
          <img className="dot" src={featured} alt="" />
          <span className="menu-item">Featured</span>
        </div>
        <div className="line-divide"></div>
        <div
          onClick={() => {
            setActive("sponsors");
            navigate("/sponsors");
          }}
          className={`dot-container ${active === "sponsors" ? "active" : ""}`}
        >
          <img className="dot" src={sponsors} alt="" />
          <span className="menu-item">Sponsors</span>
        </div>
        <div className="line-divide"></div>
        <div
          onClick={() => {
            setActive("developers");
            navigate("/developers");
          }}
          className={`dot-container ${active === "developers" ? "active" : ""}`}
        >
          <img className="dot" src={developers} alt="" />
          <span className="menu-item">Developers</span>
        </div>
        <div className="line-divide"></div>
        <div
          onClick={() => {
            setActive("teamConfluence");
            navigate("/teams");
          }}
          className={`dot-container ${active === "teamConfluence" ? "active" : ""}`}
        >
          <img className="dot" src={group} alt="" />
          <span className="menu-item">Team Confluence</span>
        </div>
        <div className="line-bottom"></div>
      </div>
    </div>
  );
};

export default Navbar;
