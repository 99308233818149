import { useState, useEffect } from 'react';

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    // Define a callback function to handle media query changes
    const handleMediaQueryChange = (e) => {
      setMatches(e.matches);
    };

    // Attach the callback to the media query
    mediaQuery.addListener(handleMediaQueryChange);

    // Check the initial state of the media query
    setMatches(mediaQuery.matches);

    // Cleanup: Remove the listener when the component unmounts
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, [query]);

  return matches;
}

export default useMediaQuery;
