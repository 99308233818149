import React, { useRef, useEffect } from "react";
import "./featured_card.scss";
import assem from "../../assets/aseem.png";
import djtracer from "../../assets/djtracer.png";
import nikhil from "../../assets/nikhil.png";
import libas from "../../assets/livas_web.png";
import groove from "../../assets/groove_web.png";
import bob from "../../assets/bob_web.png";
import folk from "../../assets/folk_web.png";
import nukkad from "../../assets/nukkad_web.png";
import kavyanjali from "../../assets/kavanjali_web.png";

import { motion, useInView, useAnimation } from "framer-motion";

const FeaturedCard = () => {
  const leftCard1 = useRef(null);
  const isInViewLC1 = useInView(leftCard1, { once: true });
  const mainControlLC1 = useAnimation();

  const rightCard1 = useRef(null);
  const isInViewRC1 = useInView(rightCard1, { once: true });
  const mainControlRC1 = useAnimation();

  const leftCard2 = useRef(null);
  const isInViewLC2 = useInView(leftCard2, { once: true });
  const mainControlLC2 = useAnimation();

  const rightCard2 = useRef(null);
  const isInViewRC2 = useInView(rightCard2, { once: true });
  const mainControlRC2 = useAnimation();

  const leftCard3 = useRef(null);
  const isInViewLC3 = useInView(leftCard3, { once: true });
  const mainControlLC3 = useAnimation();

  const rightCard3 = useRef(null);
  const isInViewRC3 = useInView(rightCard3, { once: true });
  const mainControlRC3 = useAnimation();

  const leftCard4 = useRef(null);
  const isInViewLC4 = useInView(leftCard4, { once: true });
  const mainControlLC4 = useAnimation();

  const rightCard4 = useRef(null);
  const isInViewRC4 = useInView(rightCard4, { once: true });
  const mainControlRC4 = useAnimation();

  const leftCard5 = useRef(null);
  const isInViewLC5 = useInView(leftCard5, { once: true });
  const mainControlLC5 = useAnimation();

  const rightCard5 = useRef(null);
  const isInViewRC5 = useInView(rightCard5, { once: true });
  const mainControlRC5 = useAnimation();

  useEffect(() => {
    if (isInViewLC1) {
      mainControlLC1.start("visible");
    }
    if (isInViewRC1) {
      mainControlRC1.start("visible");
    }
    if (isInViewLC2) {
      mainControlLC2.start("visible");
    }
    if (isInViewRC2) {
      mainControlRC2.start("visible");
    }
    if (isInViewLC3) {
      mainControlLC3.start("visible");
    }
    if (isInViewRC3) {
      mainControlRC3.start("visible");
    }
    if (isInViewLC4) {
      mainControlLC4.start("visible");
    }
    if (isInViewRC4) {
      mainControlRC4.start("visible");
    }
    if (isInViewLC5) {
      mainControlLC5.start("visible");
    }
    if (isInViewRC5) {
      mainControlRC5.start("visible");
    }
  }, [
    isInViewRC1,
    isInViewLC1,
    mainControlRC1,
    mainControlLC1,
    isInViewLC2,
    mainControlLC2,
    isInViewRC2,
    mainControlRC2,
    isInViewLC3,
    mainControlLC3,
    isInViewRC3,
    mainControlRC3,
    isInViewLC4,
    mainControlLC4,
    isInViewRC4,
    mainControlRC4,
    isInViewLC5,
    mainControlLC5,
    isInViewRC5,
    mainControlRC5,
  ]);

  return (
    <div className="featured-layer">
      <div className="featured-about">
        <div className="featured-title">Featured Events</div>
        <div className="featured-container">
          <motion.div
            variants={{
              hidden: { opacity: 0, x: -250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlLC1}
            transition={{ duration: 1 }}
            ref={leftCard1}
            className="featured-event-card"
          >
            <img src={nikhil} alt="" />
            <div className="featured-card">
              <div className="featured-header">Nikhil D'Souza</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  We are thrilled to announce the star attraction of our college
                  fest's Star Night – the exceptionally talented Nikhil D'Souza.
                  With his soulful vocals and captivating melodies, Nikhil has
                  left an indelible mark on the music industry. As he takes the
                  stage, be prepared for an enchanting musical journey that
                  transcends boundaries. His presence is sure to electrify the
                  audience and make this cultural fest a night to remember. Join
                  us for an unforgettable evening of music, rhythm, and magic as
                  Nikhil D'Souza graces us with his extraordinary talent. This
                  is an experience you won't want to miss!
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: 250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlRC1}
            transition={{ duration: 1 }}
            ref={rightCard1}
            className="featured-event-card right"
          >
            <div className="featured-card">
              <div className="featured-header">Aseem Sharma</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  We are excited to introduce our headline performer for the
                  college fest's Star Night – the incredibly talented Asseem
                  Sharma. Known for his dynamic and mesmerizing stage presence,
                  Asseem is a well-renowned artist in the world of
                  entertainment. Brace yourselves for an unforgettable evening
                  filled with music, entertainment, and laughter as Asseem takes
                  the stage. His performance promises to captivate the audience
                  and elevate the cultural fest to new heights. Join us for a
                  remarkable night as Asseem Sharma showcases his remarkable
                  talent. This is an opportunity you definitely don't want to
                  miss!
                </p>
              </div>
            </div>
            <img src={assem} alt="" />
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: -250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlLC2}
            transition={{ duration: 1 }}
            ref={leftCard2}
            className="featured-event-card"
          >
            <img src={djtracer} alt="" />

            <div className="featured-card">
              <div className="featured-header">DJ Tracer</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  Get ready to groove to the electrifying beats of DJ Tracer!
                  With a cool demeanor and unmatched skill, DJ Tracer is set to
                  turn the college fest's Star Night into an epic dance party.
                  His mixes and vibes will make you move and groove all night
                  long. Be there to witness the magic as DJ Tracer transforms
                  the night into a pulsating spectacle of music and dance. It's
                  the ultimate party you don't want to miss!
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: 250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlRC2}
            transition={{ duration: 1 }}
            ref={rightCard2}
            className="featured-event-card right"
          >
            <div className="featured-card">
              <div className="featured-header">काव्यांजलि</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  "काव्यांजलि" एक अद्वितीय और मनोहारी शाम है, जो सभी भावनाओं को
                  शब्दों में पिरोने का आदान-प्रदान स्थल होता है। इस रोमांचक
                  गज़लों, प्रेम कविताओं, और हास्य कविताओं की जगह पर हर शब्द एक
                  ख़ास महक में बसा होता है। इस खास शाम में, देश के विश्व
                  प्रसिद्ध कवि और कवयित्रियों का संग्रह होता है, जो अपनी कला के
                  माध्यम से आपसी संवाद को जीवंत करते हैं। यह अनूठी शाम विचारों
                  और भावनाओं का संग्रह करती है और विचारों के महसूस होने का मौका
                  देती है, जो वाहवाही के आवाज़ में अद्वितीय रूप से व्यक्त होते
                  हैं। इस अत्यंत उत्साह और जोश से भरी भीड़ के बीच, यहाँ कला का
                  जादू चलता है और सभी को अपनी माधुरी में बुलाता है।
                </p>
              </div>
            </div>
            <img src={kavyanjali} alt="" />
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: -250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlLC3}
            transition={{ duration: 1 }}
            ref={leftCard3}
            className="featured-event-card"
          >
            <img src={libas} alt="" />
            <div className="featured-card">
              <div className="featured-header">Libas</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  "Libas," the most fashionable event at the confluence, is back
                  with a bang. Get ready to showcase your creativity by wearing
                  vibrant costumes, exuding confidence, and strutting like a
                  star on the global stage. In this 10-minute Fashion Parade,
                  participating teams present theme-based shows, blending
                  clothing, props, talent, and charisma. Each team meticulously
                  crafts a unique vision, bringing it to life through their
                  clothing lines and performances. "Libas" is a celebration of
                  artistry and self-expression, where teams compete to make
                  their mark in the fashion world. Don't miss this extraordinary
                  fusion of fashion and creativity. It's a must-see event for
                  fashion enthusiasts.
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: 250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlRC3}
            transition={{ duration: 1 }}
            ref={rightCard3}
            className="featured-event-card right"
          >
            <div className="featured-card">
              <div className="featured-header">Groove Armada</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  "Groove Armada" is a renowned Major Group dance competition,
                  focusing on the electrifying Hip-Hop theme. Over the years,
                  Groove Armada has solidified its status as one of NITKKR's
                  most beloved and prominent dance acts. This high-energy event
                  attracts the best dance groups from colleges across India,
                  creating a vibrant showcase of talent, rhythm, and innovation.
                  With participants from diverse backgrounds, the competition
                  has evolved into a melting pot of creativity, pushing the
                  boundaries of Hip-Hop dance and leaving audiences spellbound
                  with their performances. Groove Armada continues to be a
                  highlight of NITKKR's cultural calendar, celebrating the power
                  of dance and expression.
                </p>
              </div>
            </div>
            <img src={groove} alt="" />
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: -250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlLC4}
            transition={{ duration: 1 }}
            ref={leftCard4}
            className="featured-event-card"
          >
            <img src={folk} alt="" />

            <div className="featured-card">
              <div className="featured-header">Folk Dance</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  India's rich tapestry of cultures and traditions is a
                  testament to its diversity. The myriad diversities that
                  permeate every aspect of Indian life contribute to the
                  uniqueness of its culture. Indian folk and tribal dances,
                  deeply rooted in various socio-economic backgrounds and
                  age-old traditions, showcase this diversity beautifully. They
                  are a reflection of the nation's rich heritage. The
                  Inter-college Folk Dance competition is a platform where this
                  cultural wealth comes to life. College teams, inspired by the
                  cultural themes, present mesmerizing performances that not
                  only celebrate India's vibrant past but also pave the way for
                  its glorious future. It's a testament to the power of unity in
                  diversity, where different colleges come together to showcase
                  the kaleidoscope of Indian traditions through the medium of
                  dance. This competition serves as a reminder of the cultural
                  treasure that India proudly possesses and shares with the
                  world.
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: 250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlRC4}
            transition={{ duration: 1 }}
            ref={rightCard4}
            className="featured-event-card right"
          >
            <div className="featured-card">
              <div className="featured-header">Nukkad</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  "Welcome to our Cultural Fest's 'Nukkad' – a vibrant hub of
                  creativity and expression! Here at Nukkad, the fest's heart
                  and soul come alive through street plays, live music, dance
                  performances, and more. It's where our talented participants
                  and artists shine, delivering powerful messages and
                  unforgettable entertainment. Explore this dynamic space, and
                  immerse yourself in the cultural fest's spirit. Join us at
                  Nukkad for a memorable journey through art, culture, and
                  community!"
                </p>
              </div>
            </div>
            <img src={nukkad} alt="" />
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: -250 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControlLC5}
            transition={{ duration: 1 }}
            ref={leftCard5}
            className="featured-event-card"
          >
            <img src={bob} alt="" />

            <div className="featured-card">
              <div className="featured-header">Battle of Bands</div>
              <div className="featured-body">
                <div className="featured-bar"></div>
                <p className="featured-content">
                  Prepare for the ultimate clash of musical titans at our
                  college fest's Battle of Bands! Bands from across the nation
                  will take the stage, armed with their unique sounds and
                  boundless energy, to compete for the coveted title. Expect an
                  electrifying atmosphere, where genres collide and talent
                  shines. Join us for a night of musical mastery, raw passion,
                  and epic performances that will rock your world. Don't miss
                  the Battle of Bands – it's the showdown of the year!
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCard;
