import React from "react";
import "../../styles/Developers.css";
import utsav from "../../assets/utsav.png";
import abhishek from "../../assets/abhishek.png";
import karan from "../../assets/karan.png";
import rahul from "../../assets/rahul.png";
import arjit from "../../assets/arjit.png";
import shreyasya from "../../assets/shreyasya.png";
import gopal from "../../assets/gopal.png";
import natasha from "../../assets/natasha.png";
import dev from "../../assets/dev.png";
import ram from "../../assets/ram.png";
import kunal from "../../assets/kunalNew.png";
import Footer from "../Footer/Footer";
import github from "../../assets/github.png";
import linkedin from "../../assets/linkedIn.png";
import portfolio from "../../assets/portfolio.png";
import { motion } from "framer-motion";

const Developers = () => {
  const developers = [
    {
      name: "Karan",
      image: karan,
      role: "Full-Stack Developer",
      linkedin: "https://www.linkedin.com/in/karan-gupta-97571b202/",
      github: "https://github.com/guptakaran9896",
    },
    {
      name: "Utsav Soni",
      image: utsav,
      role: "Full-Stack Developer",
      linkedin: "https://www.linkedin.com/in/thealpha27/",
      github: "https://github.com/TheAlpha27",
    },
    
    {
      name: "Abhishek Kumar",
      image: abhishek,
      role: "Full Stack Developer",
      linkedin: "https://www.linkedin.com/in/abhii30/",
      github: "https://github.com/abhii30",
    },
    {
      name: "Ramswaroop",
      image: ram,
      role: "Full Stack Developer",
      linkedin: "https://www.linkedin.com/in/ramswaroop2411",
      github: "https://github.com/ramswaroop2408",
    },
    // {
    //   name: "Kunal Malik",
    //   image: kunal,
    //   role: "Secretary",
    //   linkedin: "http://linkedin.com/in/kunal-malik-229774244",
    //   github: "https://github.com/KunalHR12",
    // },
    // {
    //   name: "Dev Bansal",
    //   image: dev,
    //   role: "Secretary",
    //   linkedin: "https://www.linkedin.com/in/dev-bansal-753061209",
    //   portfolio: "https://devbansal.framer.website/",
    // },
    // {
    //   name: "Gopal Tripathi",
    //   image: gopal,
    //   role: "Secretary",
    //   linkedin: "https://www.linkedin.com/in/gopal-tripathi-117982217/",
    //   github: "https://github.com/playstargopal",
    // },
    // {
    //   name: "Natasha Singh",
    //   image: natasha,
    //   role: "Secretary",
    //   linkedin: "https://www.linkedin.com/in/natasha-singh-70436420a/",
    //   github: "https://github.com/nats545454",
    // },
    {
      name: "Arjit Kedia",
      image: arjit,
      role: "Front End Developer",
      linkedin: "https://www.linkedin.com/in/arjit-kedia-06041a236/",
      github: "https://github.com/combfreak45",
    },
    {
      name: "Rahul Sehgal",
      image: rahul,
      role: "Front End Developer",
      linkedin: "https://www.linkedin.com/in/rahul-sehgal-581041224",
      github: "https://github.com/itsrahulsehgal",
    },
    {
      name: "Shreyasya Tripathi",
      image: shreyasya,
      role: "Front End Developer",
      linkedin: "https://github.com/shreyasya07",
      github: "https://github.com/shreyasya07",
    },
  ];

  return (
    <div class="developers-container">
      <div className="dev-main">
        <div className="header">Developers</div>
        <div className="dev-card-container">
          {developers.map((e, idx) => (
            <motion.div
              variants={{
                hidden: { opacity: 0, scale: 0.5 },
                visible: { opacity: 1, scale: 1 },
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1 }}
              className="dev-card"
            >
              <div className="dev-img">
                <img src={e.image} alt="" />
              </div>
              <div className="dev-details">
                <div className="dev-name">{e.name}</div>
                <span className="dev-role">{e.role}</span>
                <div className="dev-social">
                  <img
                    onClick={() => window.open(e.github, "_blank")}
                    src={github}
                    alt=""
                  />
                  {e.name === "Dev Bansal" ? (
                    <img
                      onClick={() => window.open(e.portfolio, "_blank")}
                      src={portfolio}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={() => window.open(e.linkedin, "_blank")}
                      src={linkedin}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Developers;
